<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
</template>

<script setup>
import { useI18n } from "vue-i18n";  // 引用组件
const { t, locale } = useI18n();  // 解构调用函数
document.title = t("content.title");
</script>

<style lang="scss">
html{
  font-size: 100px;
  height: 100%;
}
body,
#app {
  height: 100%;
  font-size: 0.16rem;
  color: #2c3e50;
  text-align: center;
}

#app{
  min-width: 1000px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
