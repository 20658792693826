import instance from "../util/request";

// 登陆接口
export const userLogin = (params) => instance.post(`/pay/user/login`, params)

// 获取游戏列表
export const getGames = (params) => instance.post(`/pay/user/get_games`, params)

// 获取角色列表
export const getRoles = (params) => instance.post(`/pay/charge/get_roles`, params)

// 初始化内容
export const initContent = (params) => instance.post(`/pay/charge/init`, params)

// 网页支付获取品项
export const getProduct = (params) => instance.post(`/pay/charge/get_products`, params)

// 网页支付下单
export const makeOrder = (params) => instance.post(`/pay/charge/make_order`, params)

// 网页支付获取跳转url
export const unifiedOrder = (params) => instance.post(`/pay/charge/unified_order`, params)
