import { createI18n } from 'vue-i18n'

const messages = {
    en: {
        content: {
            title: "完美世界W-Payment Center",
            loginText: "Log In",
            accountText: "Login with",
            exitloginText: "Log out",
            gameTypeText: "Game Type",
            countryText: "Payment Region",
            payWayText: "Payment",
            payWayTitle: "Payment",
            loginExitText: "Confirm Logout",
            loginExitBtn: "Yes",
            gameTitle: "Character",
            chargeTitle: "Product",
            serverRoleText: "Please choose your server/character",
            paymentTitle: "Summary",
            obtainText: "Purchase ",
            giftsText: "+Free ",
            totalObtainText: "Total ",
            game: "Game",
            account: "Account",
            server: "Server",
            role: "Character",
            money: "Amount",
            payBtnText: "Buy Now",
            gameText: "Game",
            serverText: "Server",
            roleText: "Character",
            moneyText: "Amount",
            closeText: "Close",
            payQuestion: "If you have any problem, please ",
            contactText: "contact us",
            dialogGameText: "Please select a game",
            dialogFormText: "Enter your account or password!",
            dialogRulesText: "Login failed!",
            surePayText: "Buy Now",
            paymentText: "Processing",
            password: "Password",
            mobileGame: 'Mobile Game',
            agreementText: 'I confirm my character info and product are correct',
            agreementTip: '(Please refer to the payment page for the actual paid price)',
            agreementErr: 'Please confirm your character info and product are correct',
            paysuccText: "Payment Success",
            payfailText: "Payment Failed",
            tipsText: "Tips：If you have any problem, please contact us",
            backText: "Returning to the product page within 5 sec",
            qrCodeDesc: "Please scan the QR Code with WeChat. Items will be delivered to you in game when you complete the payment.",
        },
    },
    cn: {
        content: {
            title: "完美世界W-储值中心",
            loginText: "登入",
            accountText: "使用以下账号登入",
            exitloginText: "退出登入",
            gameTypeText: "当前游戏类型",
            countryText: "当前所在国家或地区",
            payWayText: "选择支付方式",
            loginExitText: "确认登出",
            loginExitBtn: "确认",
            gameTitle: "确认您的游戏资讯",
            payWayTitle: "请您选择付款方式",
            chargeTitle: "选择储蓄类型",
            serverRoleText: "请您选择区服角色",
            paymentTitle: "确认付款清单",
            obtainText: "获得",
            giftsText: "赠送",
            totalObtainText: "总共获得",
            game: "游戏",
            account: "账号",
            server: "伺服器",
            role: "角色",
            money: "定价金额",
            payBtnText: "确认支付",
            gameText: "游戏名称",
            serverText: "区服名称",
            roleText: "角色名称",
            moneyText: "储值金额",
            closeText: "关闭",
            payQuestion: "如果支付遇到问题,请",
            contactText: "联系客服",
            dialogGameText: "请选择游戏",
            dialogFormText: "账号或密码不能为空",
            dialogRulesText: "账号或密码错误",
            surePayText: "确认付款",
            paymentText: "付款中",
            password: "密码",
            mobileGame: '手机游戏',
            agreementText: '我确认购买的商品与角色信息正确无误',
            agreementTip: '（实际金额以支付界面为准）',
            agreementErr: '请确认您购买的商品与角色信息正确无误',
            paysuccText: "支付成功",
            payfailText: "支付失败",
            tipsText: "Tips：若遇到支付问题，可联系客服确认",
            backText: "页面将于5秒内自动返回储值页",
            qrCodeDesc: "请使用微信扫二维码付款，付款成功后请至游戏内确认商品。",
        }
    },
    tw: {
        content: {
            title: "完美世界W-儲值中心",
            loginText: "登入",
            accountText: "使用以下帳號登入",
            exitloginText: "退出登入",
            gameTypeText: "當前遊戲類型",
            countryText: "當前所在國家或地區",
            payWayText: "選擇支付方式",
            loginExitText: "確認登出",
            loginExitBtn: "確認",
            gameTitle: "確認您的遊戲資訊",
            payWayTitle: "請您選擇付款方式",
            chargeTitle: "選擇儲值類型",
            serverRoleText: "請您選擇區服角色",
            paymentTitle: "確認付款清單",
            obtainText: "獲得",
            giftsText: "贈送",
            totalObtainText: "總共獲得",
            game: "遊戲",
            account: "帳號",
            server: "伺服器",
            role: "角色",
            money: "定價金額",
            payBtnText: "確認支付",
            gameText: "遊戲名稱",
            serverText: "區服名稱",
            roleText: "角色名稱",
            moneyText: "儲值金額",
            closeText: "關閉",
            payQuestion: "如果支付遇到問題,請",
            contactText: "聯繫客服",
            dialogGameText: "請選擇遊戲",
            dialogFormText: "帳號或密碼不能爲空",
            dialogRulesText: "帳號或密碼錯誤",
            surePayText: "確認付款",
            paymentText: "付款中",
            password: "密碼",
            mobileGame: '手機遊戲',
            agreementText: '我確認購買的商品與角色資訊正確無誤',
            agreementTip: '（實際金額以支付界面為準）',
            agreementErr: '請確認您購買的商品與角色資訊正確無誤',
            paysuccText: "支付成功",
            payfailText: "支付失敗",
            tipsText: "Tips：若遇到支付問題,可聯繫客服確認",
            backText: "頁面將於5秒內自動返回儲值頁",
            qrCodeDesc: "請使用WeChat掃QR Code付款，付款成功後請至遊戲內確認商品。",
        }
    },
    th: {
        content: {
            title: "完美世界W-ศูนย์เติมเงิน",
            loginText: "เข้าสู่ระบบ",
            accountText: "เข้าสู่ระบบด้วยบัญชีต่อไปนี้",
            exitloginText: "ออกจากระบบ",
            gameTypeText: "ประเภทเกมปัจจุบัน",
            countryText: "ประเทศหรือภูมิภาคปัจจุบัน",
            payWayText: "เลือกวิธีการชำระเงิน",
            loginExitText: "ยืนยันออกจากระบบ",
            loginExitBtn: "ยืนยัน",
            gameTitle: "ยืนยันข้อมูลเกมของคุณ",
            payWayTitle: "กรุณาเลือกวิธีการชำระเงิน",
            chargeTitle: "เลือกประเภทการเติมเงิน",
            serverRoleText: "กรุณาเลือกเซิร์ฟเวอร์และตัวละครของคุณ",
            paymentTitle: "ยืนยันรายการชำระเงิน",
            obtainText: "รับ",
            totalObtainText: "ได้รับทั้งหมด",
            game: "เกม",
            account: "บัญชี",
            server: "เซิร์ฟเวอร์",
            role: "ตัวละคร",
            money: "จำนวนราคา",
            payBtnText: "ยืนยันการชำระเงิน",
            gameText: "ชื่อเกม",
            serverText: "เซิร์ฟเวอร์",
            roleText: "ชื่อตัวละคร",
            moneyText: "จำนวนเงินเติม",
            closeText: "ปิด",
            payQuestion: "หากพบปัญหาในการชำระเงิน,กรุณา",
            contactText: "ติดต่อฝ่ายบริการลูกค้า",
            dialogGameText: "กรุณาเลือกเกม",
            dialogFormText: "บัญชีหรือรหัสผ่านห้ามว่าง",
            dialogRulesText: "บัญชีหรือรหัสผ่านไม่ถูกต้อง",
            surePayText: "ยืนยันการชำระเงิน",
            paymentText: "กำลังชำระเงิน",
            password: "รหัสผ่าน",
            mobileGame: 'เกม',
            agreementText: 'ได้ยืนยันว่าสินค้าที่ซื้อและข้อมูลตัวละครถูกต้องแล้ว',
            agreementTip: '（จำนวนเงินจริงขึ้นอยู่กับหน้าการชำระเงิน）',
            agreementErr: 'กรุณายืนยันว่าสินค้าที่ซื้อและข้อมูลตัวละครถูกต้อง',
            paysuccText: "ชำระเงินสำเร็จ",
            payfailText: "ชำระเงินล้มเหลว",
            tipsText: "คำแนะนำ: หากพบปัญหาในการชำระเงิน กรุณาติดต่อฝ่ายบริการลูกค้าเพื่อยืนยัน",
            backText: "หน้าจะกลับไปยังหน้าเติมเงินอัตโนมัติใน 5 วินาที",
            qrCodedesc: "โปรดใช้ WeChat เพื่อสแกนรหัส QR เพื่อชำระเงิน,หลังจากชำระเงินสำเร็จ โปรดไปที่เกมเพื่อยืนยันผลิตภัณฑ์。",
        }
    },
}

const language = () => {
    let lang = sessionStorage.getItem("lang")
    if (!lang) {
        return 'tw'
    }
    return lang
}

const i18n = createI18n({
    legacy: false, // VUE3 组合式API
    locale: language(), // 默认cn语言环境
    fallbackLocale: '',  //备用语言环境
    messages
})

export default i18n
