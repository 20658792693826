/**
 * 文件说明
*/

/**
 * 设备和环境检查判断
 * @param 无
 * @return Boolean 真或者假
 */
const userAgent = navigator.userAgent.toLowerCase();
const isMobile = /mobile/i.test(userAgent);
const isAndroid = /android/i.test(userAgent);
const isIOS = /iphone|ipad|ipod/i.test(userAgent);
const isWX = /MicroMessenger/i.test(userAgent);
/**
 * 判断是否手机号吗
 * @method isPhoneNum
 * @param num 字符串类型，需要判断的字符串
 * @return Boolean 布尔类型，真或者假
 */
const isPhoneNum = (num) => {
  return /^1\d{10}$/.test(num);
};

/**
 * 获取页面缓存
 * @method getSession
 * @param key 字符串类型，需要获取的key名
 * @return Boject 对象类型
 */
const getSession = (key) => {
  let value = sessionStorage.getItem(key);
  if (value !== "" && value !== null) {
    // let decoded = decodeURIComponent(atob(value));
    let decoded = decodeURIComponent(value);
    return JSON.parse(decoded);
  }
  return {};
};

/**
 * 存储页面缓存
 * @method saveSession
 * @param key 字符串类型，需要存储的key名
 * @param value 对象类型，需要存储的值
 */
const saveSession = (key, value) => {
  // sessionStorage.setItem(key, btoa(encodeURIComponent(JSON.stringify(value))));
  sessionStorage.setItem(key, encodeURIComponent(JSON.stringify(value)));
};

/**
 * 删除页面缓存
 * @method removeSession
 * @param key 字符串类型,需要删除的键名
 */
const removeSession = (key) => {
  sessionStorage.removeItem(key);
};

/**
 * 唯一值声明，用于标识唯一用户
 * @method uuid
 */
const uuid = () => {
  const S4 = () =>
    (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};

const obj2URL = (o) =>
  Object.keys(o)
    .map((e) => e + "=" + o[e])
    .join("&");
const observe = (obj, prop, fn = (n) => {}) => {
  let oldValue;
  Object.defineProperty(obj, prop, {
    set(newValue) {
      oldValue = newValue;
      fn(newValue);
    },
    get() {
      return oldValue;
    },
  });
};
// 合并对象方法
const assign =
  Object.assign ||
  function (t) {
    // 合并参数
    let n = arguments.length,
      s;
    for (let i = 1; i < n; i++) {
      s = arguments[i];
      if(null != s){
        for (var p in s) {
          if (Object.prototype.hasOwnProperty.call(s, p)) {
            t[p] = s[p];
          }
        }
      }
    }
    return t;
  };

const getRandomStr = (len, type) => {
  function getChar(chars) {
    return chars.charAt(Math.floor(Math.random() * chars.length));
  }
  let charsNum = "0123456789";
  let charsString = "qwertyuiopasdfghjklzxcvbnm";
  let chars = "0123456789qwertyuiopasdfghjklzxcvbnm";

  let result = "";
  let useChars;

  if (type == "string") {
    useChars = charsString;
  } else if (type == "number") {
    useChars = charsNum;
  } else {
    useChars = chars;
  }
  while (len--) {
    result += getChar(useChars);
  }
  return result;
};

function inject(container, src, tag, attr = {}) {
  let context = document.createElement(tag);
  // context.id = 'i-' + new Date().getTime()
  if (tag === "link") {
    context.href = src;
    if (!attr.hasOwnProperty("rel")) {
      context.rel = "stylesheet";
    }
  } else {
    context.src = src;
  }
  if (attr) {
    for (let i in attr) {
      context.setAttribute(i, attr[i]);
    }
  }
  document.querySelector(container).appendChild(context);
  return new Promise((resolve, reject) => {
    if (context.readyState) {
      context.onreadystatechange = () => {
        if (
          context.readyState == "loaded" ||
          context.readyState == "complete"
        ) {
          context.onreadystatechange = null;
          resolve(context);
        }
      };
    } else {
      context.onload = () => resolve(context);
    }
  });
}
/**
 * @key cookie名
 * @value cookie值
 * @options {expires: 7, domain, path} 一个对象设置cookie是过期时间，域名,路径
 * 使用示例:cookie('zjwl_key','123', {
 * : 7, domain: 'gowanme.com'})
 * */
function cookie(key, value, options) {
  var extend = function () {
    var i = 0;
    var result = {};
    for (; i < arguments.length; i++) {
      var attributes = arguments[i];
      for (var key in attributes) {
        result[key] = attributes[key];
      }
    }
    return result;
  };
  var stringifyValue = function (value) {
    try {
      var c = JSON.stringify(value);
      /^[\{\[]/.test(c) && (value = c);
    } catch (e) {}
    return encodeURIComponent(value);
  };

  var read = function (s, converter) {
    if (s.indexOf('"') === 0) {
      // This is a quoted cookie as according to RFC2068, unescape...
      s = s.slice(1, -1).replace(/\\"/g, '"').replace(/\\\\/g, "\\");
    }
    var pluses = /\+/g;

    s = decodeURIComponent(s.replace(pluses, " "));

    if (/^[\{\[]/.test(s)) {
      s = JSON.parse(s);
    }
    return Object.prototype.toString.call(converter) == "[object Function]"
      ? converter(s)
      : s;
  };

  // Write
  if (
    arguments.length > 1 &&
    !(Object.prototype.toString.call(value) == "[object Function]")
  ) {
    options = extend(options);
    if (typeof options.expires === "number") {
      var days = options.expires;
      var t = (options.expires = new Date());
      t.setMilliseconds(t.getMilliseconds() + days * 864e5);
    }

    return (document.cookie = [
      encodeURIComponent(key),
      "=",
      stringifyValue(value),
      options.expires ? "; expires=" + options.expires.toUTCString() : "", // use expires attribute, max-age is not supported by IE
      "; path=" + (options.path ? options.path : "/"),
      options.domain ? "; domain=" + options.domain : "",
      options.secure ? "; secure" : "",
    ].join(""));
  }
  // Read
  var result = key ? undefined : {};
  var cookies = document.cookie ? document.cookie.split("; ") : [];
  var l = cookies.length;
  for (var i = 0; i < l; i++) {
    var parts = cookies[i].split("=");
    var name = decodeURIComponent(parts.shift());
    var c = parts.join("=");
    if (key === name) {
      result = read(c, value);
      break;
    }
    // Prevent storing a cookie that we couldn't decode.
    if (!key && (c = read(c)) !== undefined) {
      result[name] = c;
    }
  }
  return result;
}

/**
 * 判断是否是空对象
 * @param arg 需要判断的对象
 * @return Boolean 真或者假
 */
function isEmpty(arg) {
  var type = Object.prototype.toString.call(arg);
  if (type == "[object String]") {
    return typeof arg == "undefined" || arg == null || /^\s*$/.test(arg);
  } else if (type == "[object Array]") {
    return !arg.length;
  } else if (type == "[object Object]") {
    var name;
    for (name in arg) {
      return false;
    }
    return true;
  }
  return !arg;
}

function getLocal(key) {
  if (
    window.Storage &&
    window.localStorage &&
    window.localStorage instanceof Storage
  ) {
    let value = localStorage.getItem(key);
    if (value !== "" && value !== null) {
      return JSON.parse(value);
    }
  } else {
    let value = cookie(key);
    if (value !== "" && value !== null) {
      return value;
    }
  }
  return null;
}

// 获取域名
function getReqHost() {
  var hostname = "https://magic.htgames.net"  // 正式域名
  if (rootDomain() != 'htgames.net') {
    hostname = "https://magic.huntgame.net"  // 本地测试域名
  }
  return hostname;
}

function saveLocal(key, value) {
  if (
    window.Storage &&
    window.localStorage &&
    window.localStorage instanceof Storage
  ) {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    let domain = rootDomain();
    cookie(key, value, {
      expires: 30,
      domain: domain,
    });
  }
}

function removeLocal(key) {
  if (
    window.Storage &&
    window.localStorage &&
    window.localStorage instanceof Storage
  ) {
    localStorage.removeItem(key);
  } else {
    cookie(key, "", {
      expires: -1,
    });
  }
}

function getURLparams(isHash) {
  var str;
  if (isHash) {
    str = decodeURIComponent(window.location.hash).split("?")[1] //vue带#使用
  } else {
    str = decodeURIComponent(window.location.search) // 不带#号使用
  }

  var obj = {},
    reg = /([^?=&#]+)(?:=([^?&#]+)?)/g;
  str &&
    str.replace(reg, function () {
      var key = arguments[1],
        value = arguments[2] || null;
      obj[key] = value;
    });
  return obj;
}

/*
 * 获取顶级域名
 */
function rootDomain() {
  let domain = window.location.host;
  if (!/^(\d+)\.(\d+)\.(\d+)\.(\d+)(:\d+)?$/.test(domain)) {
    domain = domain.replace(/^[^.]+\./, "");
  } else {
    // 去掉端口
    domain = domain.replace(/:\d+$/, "");
  }
  return domain;
}
function inArray(elem, arr, i){
  var len;
  var core_indexOf = [].indexOf;
  if ( arr ) {
      if ( core_indexOf ) {
          return core_indexOf.call( arr, elem, i );
      }
      len = arr.length;
      i = i ? i < 0 ? Math.max( 0, len + i ) : i : 0;

      for ( ; i < len; i++ ) {
          // Skip accessing in sparse arrays
          if ( i in arr && arr[ i ] === elem ) {
              return i;
          }
      }
  }
  return -1;
}

function each( object, callback, args ) {
    var name, i = 0,
        length = object.length,
        // Element和{}对象的length属性是undefined
        isObj = length === undefined || typeof object === 'function';
    if ( args ) {
      if ( isObj ) {
        for ( name in object ) {
          if ( callback.apply( object[ name ], args ) === false ) {
            break;
          }
        }
      } else {
        for ( ; i < length; ) {
          if ( callback.apply( object[ i++ ], args ) === false ) {
            break;
          }
        }
      }
      // A special, fast, case for the most common use of each
    } else {
      if ( isObj ) {
        for ( name in object ) {
          if ( callback.call( object[ name ], name, object[ name ] ) === false ) {
            break;
          }
        }
      } else {
        for ( ; i < length; ) {
          if ( callback.call( object[ i ], i, object[ i++ ] ) === false ) {
            break;
          }
        }
      }
    }
    return object;
}

export default {
  OS: {
    userAgent,
    isMobile,
    isAndroid,
    isIOS,
    isWX,
  },
  removeLocal,
  uuid,
  isPhoneNum,
  getSession,
  saveSession,
  removeSession,
  observe,
  obj2URL,
  getURLparams,
  saveLocal,
  getLocal,
  cookie,
  inject,
  getRandomStr,
  assign,
  isEmpty,
  inArray,
  getReqHost,
  rootDomain,
  each
};
