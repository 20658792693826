<template>
  <div class="main" v-loading="loginLoading">
    <!-- 头部 -->
    <div class="header-nav flex-center">
      <p class="header-title">{{ t("content.title") }}</p>
      <div class="header-langset">
        <el-select class="langset-slt" v-model="homeData.langVal" @change="changeLocale(homeData.langVal)"
          size="small" style="width: 100px;">
          <el-option v-for="item in store.state.langList" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
    </div>
    <!-- 盒子内容 -->
    <div class="login-info">
      <div class="game">
        <el-select class="game-select" v-model="homeData.gameId" :placeholder="t('content.game')" @change="changeGame">
          <template #prefix>
            <span class="game-icon"></span>
          </template>
          <el-option v-for="item in gameList" :key="item.value" :label="item.text" :value="item.value" />
        </el-select>
      </div>
      <div class="user">
        <el-input class="user-input" v-model="homeData.account" :placeholder="t('content.account') " clearable>
          <template #prefix>
            <span class="user-icon"></span>
          </template>
        </el-input>
      </div>
      <div class="password">
        <el-input class="user-input" type="password" v-model="homeData.password" :placeholder="t('content.password') "
          clearable show-password>
          <template #prefix>
            <span class="pwd-icon"></span>
          </template>
        </el-input>
      </div>

      <el-button style="width: 40%; height: 40px;margin: 40px auto;" color="#d85f10" @click="loginBtn">{{
        t("content.loginText")
        }}</el-button>

      <div class="login-line">
        <p class="third-text">{{ t("content.accountText") }}</p>
      </div>
      <div class="login-third">
        <div style="position: relative" class="item">
          <img src="../assets/google.png" alt="" @click="thirdLogin('google')">
        </div>

        <div class="item">
          <img src="../assets/facebook.png" alt="" @click="thirdLogin('facebook')">
        </div>

      </div>
    </div>

    <!-- 弹框 -->
    <el-dialog v-model="errorShow" :close-on-click-modal="false" width="729" top="20vh">
      <img src="../assets/dialog-error.png" alt="">
      <p style="font-size: 0.2rem;color: #d85f10;margin-top: 0.15rem;">{{ homeData.errorMsg }}</p>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs, onMounted } from 'vue'
import { useI18n } from "vue-i18n";  // 引用组件
import { useRouter } from 'vue-router';
import { getGames, userLogin, initContent } from "../api/index";
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus'
// 引入工具函數fn
import Fn from "../util/fn"
//window.Fn = Fn;

const { t, locale } = useI18n();  // 解构调用函数
const router = useRouter();
const store = new useStore();

const homeData = reactive({
  langVal: 'tw',
  langtx:'繁體中文',
  gameId: '',
  account: '',
  password: '',
  errorMsg: ''
});
const gameList = ref([]);
const loginLoading = ref(false)
const errorShow = ref(false)

onMounted(() => {
  /* 获取游戏id */
  let game_id = localStorage.getItem("default_game");
  if (game_id != null) {
    homeData.gameId = Number(game_id);
    changeGame();
  }
  let params = Fn.getURLparams(true);
  let forward = params.forward || '';
  let token = params.token || '';
  if (!Fn.isEmpty(token)) {
    // facebook登陆回调
    if (forward === 'facebook') {
      toLogin({
        mode: 4,
        third_token: token
      })
    } else if (forward === 'google') {
      // 谷歌google登陆回调
      toLogin({
        mode: 3,
        third_token: token
      })
    }
  }
  /* 获取语言 */
  let _lang = sessionStorage.getItem("lang");
  if (_lang != null) {
    homeData.langVal = _lang;
  }
  changeLocale(homeData.langVal)
  _getGames();
});

const showError= function (msg){
  errorShow.value = true;
  homeData.errorMsg = t("content."+msg);
  return;
}

// 更改语言
const changeLocale = (newLocale) => {
  homeData.langtx = "";
  locale.value = newLocale;
  store.state.langList.map((iem)=>{
    if(iem.value == newLocale){
      homeData.langtx = iem.label
    }
    return;
  })
  sessionStorage.setItem("lang", newLocale);
  sessionStorage.setItem("langText", homeData.langtx);
  document.title = t('content.title')
  _getGames()
}

// 游戏选择
const changeGame = () => {
  localStorage.setItem("default_game",homeData.gameId)
}
// 登陆
const loginBtn = () => {
  if (homeData.gameId == "") {
    return showError('dialogGameText');
  }
  if (homeData.account == "" || homeData.password == "") {
    return showError('dialogFormText');
  }
  toLogin({
    user_name: homeData.account,
    password: homeData.password,
    mode: 1
  })
}

var gameResult = {};
// 获取游戏列表
const _getGames = () => {
  gameList.value = [];
  let lang = homeData.langVal;
  if (gameResult.hasOwnProperty(lang)) {
    gameList.value = gameResult[lang];
  } else {
    getGames().then((res) => {
      if (res.code === 0) {
        gameList.value = res.data;
        gameResult[lang] = res.data
      } else {
        ElMessage.error(res.msg)
      }
    });
  }
}

// 登陆封装
const toLogin = (param) => {
  if (homeData.gameId == "") {
    return showError('dialogGameText');
  }
  param.game_id = homeData.gameId;
  loginLoading.value = true;
  userLogin(param).then((res) => {
    if (res.code === 0) {
      loginAfter();
      localStorage.setItem("default_game", homeData.gameId);
      localStorage.setItem("wtoken", res.data.wtoken);
      localStorage.setItem("user_name", res.data.user_name);
      sessionStorage.setItem("lang", homeData.langVal);
      sessionStorage.setItem("langText", homeData.langtx);
      setTimeout(() => {
        loginLoading.value = false;
        router.push('/home');
      }, 800)
    } else {
      loginLoading.value = false;
      // fb授权登录失败可能不存在游戏列表，这里加一个判断，在为0的时候主动获取一次
      if (gameList.value.length == 0) {
        _getGames();
      }
      ElMessage.error(res.msg)
    }
  }, (res) => {
    loginLoading.value = false;
    ElMessage.error(res.msg);
  })
}

// 谷歌 facebook
const thirdLogin = (way) => {
  if (Fn.isEmpty(homeData.gameId)) {
    return showError('dialogGameText');
  }
  let queryStr = "?game_id=" + homeData.gameId + "&cb=" + encodeURIComponent(window.location.href)
  window.location.href = Fn.getReqHost() + `/index/oauth/${way}${queryStr}`
}

// 重写url
const loginAfter = () => {
  let href = window.location.href;
  let hrefArr = href.split('?');
  let queryObj = [];
  if (hrefArr[1] && hrefArr[1].indexOf("&")) {
    let queryArr = hrefArr[1].split('&');
    queryArr.map(item => {
      let idx = item.indexOf("=")
      let k = item.substring(0, idx);
      if (k != 'forward' && k != 'token') {
        queryObj.push(k + '=' + item.substring(idx + 1));
      }
    })
  }
  let newHref = hrefArr[0] + (queryObj.length > 0 ? "?" + queryObj.join("&") : '');
  window.history.replaceState(null, document.title, newHref);
}

</script>

<style scoped>
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-nav {
  width: 100%;
  height: 60px;
  background-color: #d85f10;
}

.header-title {
  font-size: 22px;
  font-weight: 800;
  color: #fff;
}

.header-langset {
  position: absolute;
  right: 60px;
}

.langset-slt /deep/ .el-select__wrapper {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.langset-slt /deep/ .el-select__placeholder {
  color: #fff !important;
}

.game-select /deep/ .el-select__wrapper {
  background-color: transparent !important;
  box-shadow: none !important;
}

.user-input /deep/ .el-input__wrapper {
  box-shadow: none !important;
}

.login-info {
  margin: 100px auto auto;
  max-width: 750px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}

.game,
.user,
.password {
  max-width: 600px;
  height: 40px;
  border: 1px solid #b9bdc0;
  border-radius: 8px;
  margin: 0 auto 30px;
}

.user,.password{
  padding-top: 3px;
}

.game-icon,.user-icon,.pwd-icon {
  width: 30px;
  height: 30px;
  background: url(../assets/game.png) no-repeat;
  background-size: 100%;
}

.user-icon {
  background: url(../assets/user.png) no-repeat;
  background-size: 100%;
}

.pwd-icon {
  background: url(../assets/password.png) no-repeat;
  background-size: 100%;
}

.login-line {
  width: 6.13rem;
  height: 0.17rem;
  margin: 30px auto;
  background: url(../assets/login-way-text.png) no-repeat;
  background-size: 100%;
}

.login-third {
  width: 100%;
  height: 123px;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.login-third > div.item{
  cursor: pointer;
}

.third-text{
   color: #c0c4c6;
   font-size: 0.22rem;
   line-height: 0.2rem;
   width: 2.1rem;
   margin-left: 2rem;
}
</style>
