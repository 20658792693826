import axios from "axios";
import Fn from "./fn";
import router from '../router/index';
import "./encryption.js";
import { ElMessage } from 'element-plus'

// create an axios instance
const instance = axios.create({
  baseURL: Fn.getReqHost(),
  timeout: 30000, // request timeout
});

// request拦截器 request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("wtoken")
    if (token) {
        config.headers["wtoken"] = token
    }
    const lang = sessionStorage.getItem("lang")
    config.headers["Accept-Language"] = lang || 'tw';
        

    var dt = config.data;
    dt = Fn.assign({}, dt);
    // console.log('dt',dt);
    config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    var file = "";
    if (dt.file) {
      config.headers["Content-Type"] = "multipart/form-data";
      file = dt.file;
      delete dt.file;
    }


  if(!dt.hasOwnProperty('p')){
      // 加密
    var ts = String(new Date().getTime()).substr(0, 10); //ts
    var p = encryption.encrypt(JSON.stringify(dt), ts, 7); //p
    config.data = {
      ts, // 当前时间
      p, // 参数
    }
  }
    if (file) {
      config.data.file = file;
    }
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// respone拦截器
instance.interceptors.response.use(
  (response) => {
    const res = response.data;
    // 解密
    if (res.data && res.data.d) {
      var resDt = encryption.decrypt(res.data.d, String(res.data.ts), 7);
      res.data = JSON.parse(resDt);
    }

    if(res.code == -1){
      ElMessage.error(res.msg);
      setTimeout(() => {
        localStorage.removeItem('wtoken');
        router.push('/login');
      }, 500);
    }
    return Promise.resolve(res);
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
