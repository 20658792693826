import { createStore } from 'vuex'

export default createStore({
  state: {
    langList:[
      {
        value: 'cn',
        label: '简体中文',
      },
      {
        value: 'tw',
        label: '繁體中文',
      },
      {
        value: 'en',
        label: 'English',
      },
      {
        value: 'th',
        label: 'ภาษาไทย',
      },
    ]
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
