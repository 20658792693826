import CryptoJS from 'crypto-js'; 
window.encryption =  {
    encryptMethod: function() {
        return 'AES-128-CBC';
    },
    keySize: function() {
        var encryptMethod = this.encryptMethod();
        var aesNumber = encryptMethod.match(/\d+/)[0];
        return parseInt(parseInt(aesNumber) / 8);
    },
    decrypt: function(encryptStr, key,pos) {
        key = this.getKey(key,pos);
        var iv = this.iv(key);// the reason to be 16, please read on `encryptMethod` property.
  
        key  = CryptoJS.enc.Utf8.parse(key); //十六位十六进制数作为密钥
        iv   = CryptoJS.enc.Utf8.parse(iv); 
        encryptStr = CryptoJS.format.Hex.parse(encryptStr);
        var decrypted = CryptoJS.AES.decrypt(encryptStr,key,
            {
                iv:iv,
                mode:CryptoJS.mode.CBC,
                padding:CryptoJS.pad.ZeroPadding
            });
        return decrypted.toString(CryptoJS.enc.Utf8);
    },
    iv:function(key){
        return key.split('').reverse().join('');
    },
    getKey: function(rand, pos){
        pos = pos || 3;
        var md5Str = (CryptoJS.MD5(rand).toString()).split("");
        var i = 0;
        var temp = '';
        var len = md5Str.length;
        var keySize = this.keySize();
        do
        {
            var index = i % len;
            temp += md5Str[index]+'';
            i += pos;
        }
        while (temp.length < keySize);
        return temp;
    },
    encrypt: function(string, key,pos) {
        key = this.getKey(key,pos);
        var iv = this.iv(key);// the reason to be 16, please read on `encryptMethod` property.
  
        key  = CryptoJS.enc.Utf8.parse(key); //十六位十六进制数作为密钥
        iv   = CryptoJS.enc.Utf8.parse(iv);
        var encrypted = CryptoJS.AES.encrypt(string,key, {
            iv:iv,
            mode:CryptoJS.mode.CBC,
            padding:CryptoJS.pad.ZeroPadding
        });
        return encrypted.ciphertext.toString().toUpperCase();
    }
  }
  export default encryption;
  