import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './locales/index'  // 引入配置好的文件

// Element Plus
import 'element-plus/theme-chalk/index.css'  // 引入 ElementPlus 组件样式
// 图标和组件需要分开引入
import ElementPlus from 'element-plus'   // 引入 ElementPlus 组件

// 引入rem
// import "./util/devicerem";

// 监听本地存储删除刷新页面
window.addEventListener('storage', function (e) {
    //这个e返回的是哪个值被更改，更改后的值跟更改之前的值
    if (!localStorage.getItem("wtoken")) {
        // 这里是判断本地存储wtoken不存在的时候写的逻辑，这里可以让他跳转到登陆页面
        location.reload()
    }
})

createApp(App).use(store).use(router).use(ElementPlus).use(i18n).mount('#app')

